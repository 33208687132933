import Introduction, { Props } from './Introduction';
import coverImage from '../../../../assets/images/psychological-well-being.png';
import {getTitleByKey, PSYCH_WELL_BEING} from '../../../../libs/helpers/surveyIntroductionHelper';
import './Introduction.css';

const PsychologicalWellBeing = (props: Props) => {
  return (
    <Introduction
      title={getTitleByKey(PSYCH_WELL_BEING)}
      coverImage={coverImage}
      {...props}
    >
      There are many definitions of psychological well-being because it is a complex and multifaceted construct. 
      It can be defined as the presence of positive (or absence of negative) feelings and generally includes happiness, 
      holistic wellness and life satisfaction.
      <br />
      <br />
      <b>
        This section will ask you questions about how you experience your
        psychological well-being. Some questions will be general and others will
        be work-specific.
      </b>
      <br />
      <br />
      It is important to note that your answers to these questions do not
      qualify as a clinical diagnosis, and if you have concerns about your
      levels of resilience, it is advised you consult a qualified health
      professional.
      <br />
      <br />
      This assessment is designed to gather your honest and open feedback. Rest
      assured, no identifiable information will be shared with anyone, including
      your team inviter. Your responses will remain confidential.
      <br />
      <br />
    </Introduction>
  );
};

export default PsychologicalWellBeing;
