import React from "react";
import { connect } from "react-redux";
import Quiz from "../../../../interfaces/Quiz";
import OpenGraph from "../../../../components/OpenGraph";
import QuizLayout from "../../../../components/layout/QuizLayout";
import Loader from "../../../../components/quiz/loader/Loader";
import Situation from "../../../../components/quiz/situation/Situation";
import QuestionContainer from "../../../../components/question-container/QuestionContainer";
import ValidationError from "../../../../components/ValidationError";
import PartnerInfoForm from "../../../../components/quiz/forms/PartnerInfoForm";
import SubmitButton from "../../../../components/SubmitButton";
import useOpenGraph from "../useOpenGraph";
import useStartForm from "./useStartForm";
import useStartQuiz from "../useStartQuiz";
import * as utils from "../../../../libs/utils/form.utils";
import MindonlyPopup from "../../../../components/quiz/poup/MindonlyPopup";
import {
  getModalQuizTitle,
  WORKPLACE_TEAM,
} from "../../../../libs/helpers/quizHelper";
import { useParams } from "react-router-dom";

interface Props {
  quiz: Quiz;
}

const StartQuizPage: React.FC<Props> = (props) => {
  const ogProps = useOpenGraph();
  const form = useStartForm(props);
  const { slug = "" } = useParams();

  const {
    isLoading,
    showDisclaimer,
    setShowDisclaimer,
    header,
    navbar,
    progress,
    situationSurvey,
    canGoForward,
    stepForward,
  } = useStartQuiz({
    additionalSteps: [{ name: "Invite Your Team Members" }],
    ...props,
  });

  const onSubmit = (e: any) => {
    e?.preventDefault();

    canGoForward() ? stepForward() : form.submitForm();
  };

  const getFieldProps = (field: string) => utils.getFieldProps(form, field);
  const isInvalidField = (field: string) => utils.isInvalidField(form, field);
  const assessmentText =
    slug === WORKPLACE_TEAM
      ? "Attachment at Work team assessment"
      : getModalQuizTitle(slug);

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout header={header} proggres={progress} navbar={navbar}>
        {isLoading && <Loader />}
        {situationSurvey ? (
          <>
            <Situation
              situationSurvey={situationSurvey}
              form={form}
              onSubmit={onSubmit}
            />
            <MindonlyPopup
              isOpened={showDisclaimer}
              setIsOpened={setShowDisclaimer}
              title={getModalQuizTitle(slug)}
              text={
                <>
                  You are taking part in the {assessmentText}. Your personal
                  information will remain private to you. Team reports will
                  contain averages of all team members' scores.{" "}
                  <strong>
                    A minimum of 4 team members is required for team reports to
                    be generated.
                  </strong>
                </>
              }
            />
          </>
        ) : (
          <form onSubmit={onSubmit}>
            <QuestionContainer
              autoHighlight={false}
              focusIndex={-1}
              setFocusIndex={() => {}}
              questions={[
                {
                  title: "Your information",
                  children: (
                    <>
                      <div className="mb-3">
                        <input
                          autoComplete="none"
                          placeholder="Your name"
                          {...getFieldProps("name")}
                        />
                        {isInvalidField("name") && (
                          <ValidationError error={form.errors.name} />
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          autoComplete="none"
                          placeholder="Your email"
                          {...getFieldProps("email")}
                        />
                        {isInvalidField("email") && (
                          <ValidationError error={form.errors.email} />
                        )}
                      </div>
                    </>
                  ),
                },
                {
                  title: "Team Member’s Information",
                  children: (
                    <PartnerInfoForm
                      form={form}
                      partnerMin={3}
                      partnerMax={10}
                      titlePrefix="Team Member"
                      addButtonText="Add more team members"
                    />
                  ),
                },
              ]}
            />
            <div className="text-center my-5 pt-4">
              <SubmitButton isSubmitting={form.isSubmitting}>
                Continue
              </SubmitButton>
            </div>
          </form>
        )}
      </QuizLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
