import { Burnout } from '../models/Burnout';
import { Resilience } from '../models/Resilience';
import { WorkplaceIndividual } from '../models/WorkplaceIndividual';

export const WORKPLACE_INDIVIDUAL = 'workplace-individual';
export const WORKPLACE_TEAM = 'workplace-team';
export const BURNOUT_TEAM = 'burnout-team';
export const BURNOUT = 'burnout';
export const RESILIENCE = 'resilience';
export const RESILIENCE_TEAM = 'resilience-team';
export const TEAM_STATE = 'team-state';

export const MODE_SINGLE = 'single';
export const MODE_TEAM = 'team';

const COMPLETED_TEAMS = [WORKPLACE_TEAM, BURNOUT_TEAM, RESILIENCE_TEAM, TEAM_STATE];
const LS_DEFAULT_ROUTE = 'preliminary-result';

const quizModels: any = {
  [WORKPLACE_INDIVIDUAL]: WorkplaceIndividual,
  [BURNOUT]: Burnout,
  [RESILIENCE]: Resilience,
};

const modalQuizTitles: any = {
  [WORKPLACE_TEAM]: 'Attachment at Work Assessment: Team Dynamics & Well-Being',
  [BURNOUT_TEAM]: 'Team Burnout Assessment',
  [RESILIENCE_TEAM]: 'Team Resilience Assessment',
  [TEAM_STATE]: 'Team State Assessment',
};

const quizLastStepRoutes = COMPLETED_TEAMS.reduce((routes, team) => {
  routes[team] = 'completed';
  return routes;
}, {} as Record<string, string>);

export const getQuizModel = (key: string) => quizModels[key];

export const getModalQuizTitle = (key: string) => modalQuizTitles[key] ;

export const getQuizLastStepRoute = (key: string): string => {
  const path = quizLastStepRoutes[key] || LS_DEFAULT_ROUTE;

  return `/${key}/${path}`;
};
