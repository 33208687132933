import { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { Link } from "react-router-dom";
import env from "../../../env";
import "./MindonlyPopup.css";

const MindonlyPopup = ({ isOpened, setIsOpened, title, text }: any) => {
  const [policyConsent, setPolicyConsent]: any = useState(false);
  const [recieveEmailsConsent, setRecieveEmailsConsent]: any = useState(false);

  const onContinue = () => {
    if (policyConsent && recieveEmailsConsent) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    setPolicyConsent(false);
    setRecieveEmailsConsent(false);
  }, [isOpened]);

  return (
    <Modal
      id="workplace-disclaimer"
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      data-bs-backdrop="static"
    >
      <Modal.Header showCloseButton={false}>
        <h5 className="mb-0">{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        <p className="mb-0">
          Please indicate your preferences by ticking the appropriate boxes
          below:
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-footer pt-0 justify-content-between">
        <div>
          <div className="form-check">
            <input
              id="policy-agree"
              type="checkbox"
              className="form-check-input"
              value="on"
              onChange={(e: any) => setPolicyConsent(e.target.checked)}
              checked={policyConsent}
            />
            <label className="form-check-label" htmlFor="policy-agree">
            {'I agree with '}
              <Link target="_blank" to={`${env.mindOnlyUrl}/terms`}>
                Terms and Conditions
              </Link>{" "}
              {' and '}
              <Link target="_blank" to={`${env.mindOnlyUrl}/privacy-policy`}>
                Privacy Policy
              </Link>
            </label>
          </div>
          <div className="form-check">
            <input
              id="recieve-emails-agree"
              type="checkbox"
              className="form-check-input"
              value="on"
              onChange={(e: any) => setRecieveEmailsConsent(e.target.checked)}
              checked={recieveEmailsConsent}
            />
            <label className="form-check-label" htmlFor="recieve-emails-agree">
              I agree to receive emails from MindOnly
            </label>
          </div>
        </div>
        <Modal.ActionButton
          className="btn btn-secondary btn-agree"
          onClick={onContinue}
          disabled={!policyConsent || !recieveEmailsConsent}
        >
          Continue
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MindonlyPopup;
