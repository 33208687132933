import { connect } from "react-redux";
import {
  getQuizStat,
  invalidateQuizStatState,
} from "../../libs/redux/actions/quizStatActions";
import { invalidateSurveyQuestionsState } from "../../libs/redux/actions/surveyQuestionsActions";
import {
  completeSurveyStat,
  skipSurveyStat,
  startSurveyStat,
} from "../../libs/redux/actions/surveyStatActions";
import { MODE_TEAM } from "../../libs/helpers/quizHelper";
import QuizLayout from "../../components/layout/QuizLayout";
import Loader from "../../components/quiz/loader/Loader";
import QuizSurvey from "../../components/quiz/survey/QuizSurvey";
import useQuizPage from "./useQuizPage";
import useOpenGraph from "./useOpenGraph";
import OpenGraph from "../../components/OpenGraph";
import MindonlyPopup from "../../components/quiz/poup/MindonlyPopup";
import { SETUP_PROFILE } from "../../libs/helpers/surveyHelper";
import { getTitleByKey } from "../../libs/helpers/surveyIntroductionHelper";
import {
  getModalQuizTitle,
  WORKPLACE_TEAM,
} from "../../libs/helpers/quizHelper";

const QuizPage = (props: any) => {
  const {
    isLoading,
    setIsLoading,
    quizStat,
    slug,
    surveyStat,
    surveyStatCount,
    showDisclaimer,
    setShowDisclaimer,
    currentIndex,
    startStep,
    stepBack,
    skipStep,
    completeStep,
    navbar,
    setNavbar,
    stepProgress,
    setStepProgress,
    canStepBack,
  }: any = useQuizPage(props);

  const ogProps = useOpenGraph(surveyStat);

  const stepOffset =
    quizStat?.is_inviter && quizStat?.mode === MODE_TEAM ? 1 : 0;
  const totalSteps = surveyStatCount + stepOffset;
  const step = currentIndex + stepOffset + 1;
  const assessmentText =
    slug === WORKPLACE_TEAM
      ? "Attachment at Work team assessment"
      : getModalQuizTitle(slug);

  const header = {
    title: quizStat?.name,
  };

  const percentage = calculatePercentage(
    surveyStatCount,
    currentIndex,
    stepProgress
  );

  const isStarted = () => surveyStat?.is_started === 1;
  const IntroTitle = !isStarted()
    ? getTitleByKey(surveyStat?.quiz_survey?.introduction)
    : null;

  const progress = {
    step,
    totalSteps,
    title: IntroTitle ?? surveyStat?.quiz_survey?.survey?.name,
    percentage,
  };

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout header={header} proggres={progress} navbar={navbar}>
        {isLoading && <Loader />}
        {surveyStat ? (
          <>
            <QuizSurvey
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              surveyStat={surveyStat}
              canStepBack={canStepBack()}
              completeStep={completeStep}
              startStep={startStep}
              skipStep={skipStep}
              stepBack={stepBack}
              setNavbar={setNavbar}
              setProgress={setStepProgress}
            />
            {surveyStat?.quiz_survey?.slug === SETUP_PROFILE && (
              <MindonlyPopup
                isOpened={showDisclaimer}
                setIsOpened={setShowDisclaimer}
                title={getModalQuizTitle(slug)}
                text={
                  <>
                    You are taking part in the {assessmentText}. Your personal
                    information will remain private to you. Team reports will
                    contain averages of all team members' scores. <strong>
                    A minimum of 4 team members is required for team reports to
                    be generated.
                    </strong>
                  </>
                }
              />
            )}
          </>
        ) : (
          <div style={{ height: "200vh" }} />
        )}
      </QuizLayout>
    </>
  );
};

const calculatePercentage = (
  surveyCount: number,
  currentIdx: number,
  stepProgress: number
) => {
  const totalSteps = surveyCount + 1;
  const stepPerc = 100 / totalSteps;

  if (surveyCount === 0) return 0;

  const percentage =
    (currentIdx + 1) * stepPerc + (stepProgress / 100) * stepPerc;

  return Math.round(percentage);
};

const mapStateToProps = (state: any) => {
  return {
    quizStat: state.quizStat,
  };
};

const mapDispatchToProps = {
  getQuizStat,
  startSurveyStat,
  completeSurveyStat,
  skipSurveyStat,
  invalidateQuizStatState,
  invalidateSurveyQuestionsState,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
